








































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Pagina extends Vue {
  private menu: any = "";
  private menu_destacado: any = "";
  private menuLateral: any = [];
  private pagina: any = {
    id: "",
    titulo: "",
    nombre_web: "",
    fecha: "",
    banner: "",
    banner_mobile: "",
    contenido: "",
    direccion: "",
    telefono: "",
    email: "",
    horario: "",
    director: "",
    fecha_modificacion: "",
    tipo: "",
    estado: "",
    menu_lateral: ""
  };
  private nombreWeb: any = "";
  private columnasTablaData = [
    {
      name: "nombre",
      label: "Documento",
      align: "left"
    },
    {
      name: "dato",
      label: "Acción",
      align: "left"
    }
  ];
  private dataPagina: any = [];

  get muestraMenu() {
    if (
      this.pagina &&
      this.pagina.menu_lateral == 1 &&
      this.menuLateral.length > 0
    ) {
      return " col-xs-11 col-sm-11 col-md-8 col-lg-8 col-xl-8";
    }
    return " col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11";
  }

  private beforeMount() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getPagina();
    } else {
      this.routerGo("Home");
    }
  }
  private mounted() {}

  private getPagina() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("pagina?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.pagina = res.data;
        if (this.pagina.banner) {
          this.pagina.banner =
            "url_media/" +
            this.pagina.banner;
        } else {
          this.pagina.banner =
            "url_media/bannerPagina/default.jpg";
        }
        if (this.pagina.banner_mobile) {
          this.pagina.banner_mobile =
            "url_media/" +
            this.pagina.banner_mobile;
        } else {
          this.pagina.banner_mobile =
            "url_media/bannerPagina/default_mobile.jpg";
        }
        document.title = this.pagina.titulo + " - Maipú Sustentable";

        //MetaData generica
        document.title = this.pagina.titulo + " - Maipú Sustentable";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", "Maipú Sustentable");
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.pagina.banner_mobile
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.pagina.titulo + " - Maipú Sustentable"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", "Maipú Sustentable");
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.pagina.banner_mobile);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.pagina.titulo + " - Maipú Sustentable"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", "Maipú Sustentable");
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.pagina.banner_mobile);

        if (this.pagina.director && this.pagina.director != "") {
          this.dataPagina.push({
            nombre: "Director/a",
            dato: this.pagina.director
          });
        }
        if (this.pagina.direccion && this.pagina.direccion != "") {
          this.dataPagina.push({
            nombre: "Dirección",
            dato: this.pagina.direccion
          });
        }
        if (this.pagina.email && this.pagina.email != "") {
          this.dataPagina.push({
            nombre: "Correo",
            dato: this.pagina.email
          });
        }
        if (this.pagina.telefono && this.pagina.telefono != "") {
          this.dataPagina.push({
            nombre: "Teléfono",
            dato: this.pagina.telefono
          });
        }
        if (this.pagina.horario && this.pagina.horario != "") {
          this.dataPagina.push({
            nombre: "Horario",
            dato: this.pagina.horario
          });
        }
        this.setMenu();
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        this.routerGo("Home");
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private llamar() {
    const numero = this.pagina.telefono;
    window.open("tel:" + numero);
  }

  private sendMail() {
    const correo = this.pagina.email;
    window.open("mailto:" + correo);
  }

  private irGoogleMaps() {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" + this.pagina.direccion
    );
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu_maipu.json");
    this.menu = await runtimeConfig1.json();
    // const runtimeConfig2 = await fetch("/menu_destacado.json");
    // this.menu_destacado = await runtimeConfig2.json();
    if (this.pagina.menu_lateral == 1) {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.getSubMenu(this.menu[i], this.pagina.nombre_web)) {
          if (this.menu[i].sub_menu.length > 0) {
            this.menuLateral = this.menu[i].sub_menu;
            return false;
          }
        }

        // this.getSubMenu(this.menu[i], this.pagina.nombre_web);
      }
      // for (let i = 0; i < this.menu_destacado.length; i++) {
      //   if (this.menu_destacado[i].nombre_web == this.pagina.nombre_web) {
      //     this.menuLateral = this.menu_destacado;
      //     break;
      //   }
      // }
    }
  }

  private getSubMenu(element: any, nombre_web: string) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (this.getSubMenu(element.sub_menu[j], nombre_web)) {
            this.menuLateral = element.sub_menu;
            return false;
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(nombre_web: string) {
    if (this.$router.currentRoute.params.nombre == nombre_web) {
      return " background-color: #10174A !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
    } else {
      return "";
    }
  }
  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal") {
      this.routerGo("Concejo");
    } else if (nombreWeb == "centros-de-atencion-municipal") {
      this.routerGo("Cam");
    } else if (nombreWeb == "oficinas-y-telefonos-municipales") {
      this.routerGo("Telefonos");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }
}
